main {
    .col-left {
        max-width: 544px;
        margin-right: 20px;
    }

    .txt {
        font-size: 20px;
    }

    .inputmain-banner {
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);

        .input_control {
            border: 0;
        }
    }

    .main-banner-bg {
        background: url("../../../public/assets/img/main_banner-bg.png") no-repeat center top;
        background-size: cover;
        padding-top: 8rem;
        padding-bottom: 7rem;
    }

    img.banner-img-RS {
        max-width: 650px;
        position: relative;
        right: -7.5rem;
    }


}

//  cuisines section ==============
.top_cuisines {
    padding-bottom: 50px;

    .cuisines_cout {
        background: #FFFFFF;
        box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 25px;
    }

    .country_group {
        display: flex;
        flex-wrap: wrap;


        .country-name {
            width: 25%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
    }

}

// media query here ===================

// media query here 1440px ===================
@media (max-width: 1440px) {
    main {
        img.banner-img-RS {
            max-width: 576px;
            right: -5rem;
        }
    }
}

// media query here 1320px ===================
@media (max-width: 1320px) {
    main {
        img.banner-img-RS {
            max-width: 576px;
            right: -3rem;
        }
    }
}

// media query here 1199px ===================
@media (max-width: 1199px) {
    .top_cuisines .country_group .country-name {
        width: 33.3%;
    }

    main {
        .col-left {
            width: 443px;
            max-width: 443px;
            min-width: 443px;
        }

        .col-right {
            text-align: end;
        }

        img.banner-img-RS {
            max-width: 85%;
            right: 0;
        }
    }
}

// media query here 1199px ===================
@media (max-width: 991px) {
    .top_cuisines .country_group .country-name {
        width: 33.3%;
    }

    main {
        .col-left {
            width: 314px;
            max-width: 314px;
            min-width: 314px;
        }

        .txt {
            font-size: 16px;
        }

        .main-banner-bg {
            padding-top: 6rem;
            padding-bottom: 3rem;
        }
    }

}