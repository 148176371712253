footer {
    background: #FEF3F3;

    .inputGroup {
        input {
            min-width: 400px;
        }

        .cusbtn {
            min-width: 170px;
        }
    }

    .footer-top {
        padding: 40px 0;
        border-style: dashed;
        border-top: none;
        border-left: none;
        border-right: none;
        border-width: 2px;
        border-color: #c9c1c1;
    }

    .footer-middle {
        padding: 30px 0;
    }

    .footer-logo {
        img {
            width: 150px;
        }
    }

    .footer-widget {
        ul {
            display: flex;
            align-items: center;

            li {
                margin-right: 60px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        a {

            font-size: 18px;
            color: #2E2E2E;
            transition: ease-in-out 0.5s;
            font-family: "Poppins-Midium";

            &:hover {
                color: #F14647;
            }
        }
    }

    .footer-social {
        ul {
            display: flex;
            align-items: center;

            li {
                margin-right: 15px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .social-title {
                img {
                    border-radius: 50%;
                    transition: ease-in-out 0.9s;

                    &:hover {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }
}

// media query 1199px =========================
@media (max-width: 1199px) {
    footer {
        .inputGroup input {
            min-width: 360px;
            max-width: 360px;
        }

        .footer-logo img {
            width: 120px;
        }

        .footer-widget ul li {
            margin-right: 40px;
        }
    }

    .social-link {
        max-width: 40px;
    }
}

// media query 1199px =========================
@media (max-width: 991px) {

    footer {
        .footer-social ul li {
            margin-right: 15px;
        }

        .footer-widget ul li {
            margin-right: 25px;
        }

        .footer-logo img {
            width: 100px;
        }

        .social-link {
            max-width: 35px;
        }

        .inputGroup .cusbtn {
            min-width: 130px;
        }

    }
}

// media query 1199px =========================
@media (max-width: 767px) {
    footer .footer-top {
        padding: 30px 0;
    }
}