@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Poppins-Midium";
    src: url("../fonts/Poppins-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url('../fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: "Poppins-Bold";
    src: url('../fonts/Poppins-Bold.ttf');
    font-weight: 700;
}