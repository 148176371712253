// home section =================
.dgrid {
    // display: grid;
}

.imgw_s {
    width: 22px;
    height: 22px;
}

.restaurants_near {
    padding: 50px 0;
    background: url("../../../public/assets/img/restaurant_bg.png") no-repeat center top;
    background-size: cover;

    .head4 {
        color: #F14647;
    }

    .head3 {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 45px;

        &::before {
            position: absolute;
            content: "";
            width: 80px;
            height: 3px;
            background: #F14647;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            border-radius: 50px;
        }
    }

}

.dis_dV {
    width: 22px;
    height: 22px;
    border-radius: 4px;
}

.restaurants_group {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;

    .txt {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        &.nowrap {
            white-space: initial;
            overflow: initial;
            text-overflow: initial;
            word-break: break-all;

            span {
                display: inline-block;
            }
        }
    }

    .restaurants_item {
        background-color: $white;
        padding: 10px 10px 15px 10px;
        border-radius: 10px;
        width: calc(25% - 15px);
        box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.08);
    }

    h6 {
        margin-bottom: 0;
    }

    .restaurant-img {
        width: 100%;
        object-fit: cover;
        max-height: 200px;

        border-radius: 10px;
    }
}

.grid_item_multiple {
    .restaurants_group .restaurants_item {
        width: calc(33.5% - 15px);
    }
}

// ================ end 
.restaurant_inner_bg {
    background: url("../../../public/assets/img/restaurant_inner_bg.png") no-repeat center top;
    background-size: cover;
    padding-top: 6.5rem;
    padding-bottom: 5rem;

    .inputGroup {
        position: relative;

        &::after {
            position: absolute;
            content: "";
            right: 0;
            background: no-repeat url("../../../public/assets/img/searchjilmil.png");
            background-size: 100%;
            width: 60px;
            height: 27px;
            right: 19%;
            background-position: center;

            @media (max-width: 1320px) {
                right: 12%;
            }

            @media (max-width: 1199px) {
                right: 0;
                top: 47px;
            }
        }
    }

    .inputmain-banner {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .input_control {
        max-width: 600px;
    }
}

.restaurantsNear {
    padding: 50px 0;
}

.res_menu_screen {
    .grid_item_single {
        .restaurants_item {
            flex-direction: column;

        }

        .res_img {
            width: 100%;
            margin-right: 0;
        }
    }

    .restaurants_group .restaurant-img {
        max-height: 240px;
    }
}

.restaurant_view_all {
    flex: 1;
    width: calc(100% - 390px);

    .head4 {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 45px;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        &::before {
            position: absolute;
            content: "";
            width: 50px;
            height: 3px;
            background: #F14647;
            bottom: 0;
            left: 0;
            border-radius: 50px;
        }
    }
}

.grid_item_single {
    .restaurants_item {
        display: flex;
        width: 100%;
    }

    .res_img {
        width: 100%;
        margin-right: 20px;

        &.resimg_fix {
            min-width: 160px;
            max-width: 160px;
        }
    }

    .res_content {
        width: 100%;

        &.dgrid {
            width: calc(100% - 180px);
        }
    }
}

.no-need_arrow {
    .accordion-button {
        &::after {
            display: none;
        }
    }
}

.accordion-header {
    .filter-title.show {
        background: #F14647;
        border-radius: 5px;
    }
}

.tabbtn {
    background: #fff;
    border-radius: 6px;
    padding: 10px 14px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);

    .Active_grout-item {
        display: none;
    }

    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }

    &.active {
        background: #F14647;

        .deActive_grout-item {
            display: none;
        }

        .Active_grout-item {
            display: inline;
        }
    }
}

.custab_btn {
    .btn {
        img.filter {
            filter: invert(1);

        }
    }

    img.filter {

        filter: invert(0);
    }
}

.accordion-button {
    &.collapsed {
        img.filter {
            filter: invert(1);
        }
    }

    img.filter {
        filter: invert(0);
    }
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../../../public/assets/img/arrow_up_acc.svg") !important;
    transform: rotate(0deg) !important;
    background-position: center;
    background-size: 16px;
}

.item_filter_box .accordion-body {
    padding-left: 48px;
}

.restaurantsNear_menu {
    padding: 50px 0;

    .filterbtn {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 12px 20px;
        border-radius: 5px;
        min-height: 50px;
        color: #2E2E2E;
        background: transparent;

        img.filter {
            filter: invert(1);

        }

        &.active {
            color: #fff;
            background-color: #F14647;

            img.filter {
                filter: invert(0);

            }
        }

        &:hover {
            color: #fff;
            background-color: #F14647;

            img.filter {
                filter: invert(0);

            }
        }
    }

    ul {
        margin-top: 30px;

        li {
            margin-bottom: 7px;
        }
    }
}

.offer_box {
    .white_box {
        display: flex;
        align-items: center;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }

    .offer-img_icon {
        width: 60px;
        margin-right: 10px;
    }
}

.product_dis {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.right_box_hug {
    width: 300px;
    min-width: 300px;
    margin-left: auto;
    height: 100%;
}

.hungry-img {
    max-width: 180px;
    width: 100%;
    display: table;
    margin: auto;
}

// media query here

// media query 1199px
@media (max-width: 1199px) {
    .product_dis {
        grid-template-columns: repeat(1, 1fr);
    }

    .grid_item_multiple {
        .restaurants_group .restaurants_item {
            width: calc(50.5% - 15px);
        }
    }

    .grid_item_single {
        .restaurants_group .restaurants_item {
            width: 100%;
        }
    }

    .restaurants_group .restaurants_item {
        width: calc(33.4% - 15px);
    }
}

@media (max-width: 991px) {

    .restaurantsNear_menu .filterbtn {
        padding: 10px;
    }

    .offer_box .white_box {
        margin-right: 0;
    }



    .filterbtn {
        flex-direction: column;
    }

    .restaurant_inner_bg {
        padding-top: 5.5rem;
        padding-bottom: 3rem;
    }

    .item_filter_box .accordion-body {
        padding-left: 10px;
    }

    .restaurants_near {
        padding: 30px 0;

        .head3 {
            padding-bottom: 10px;
            margin-bottom: 30px;
        }
    }

    .restaurants_group {
        .head6 {
            font-size: 14px;
        }

        .txt {
            font-size: 14px;
        }
    }
}