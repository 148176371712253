.filter-title {
    font-size: 16px;
}

.rating_modal_box {
    text-align: center;

    .star-ratings {
        display: flex !important;
        justify-content: space-evenly;
    }

    .star-container {
        padding: 10px !important;
        background-color: #F9F9F9;
        border-radius: 50%;

        svg {
            width: 30px !important;
            height: 30px !important;
        }
    }
}

sup {
    color: #F14647;
}

.pointer {
    cursor: pointer;
}

.minonehunt {
    min-width: 110px;
}

:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.add_num {
    display: flex;
    width: fit-content;
    min-width: fit-content;
    justify-content: space-around;
    height: 46px;
    align-items: center;
    border: 1px solid #F14647;
    border-radius: 5px;

    .btnAdd_pr {
        width: 35px;
        color: #F14647;
        font-size: 25px;
        background: transparent;
        position: relative;
        top: -3px;
    }

    div {
        color: #F14647;
        font-size: 16px;
        font-family: "Poppins-SemiBold";
        padding: 0 5px;
    }
}

.needmin-hmain {
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1400px) {
        min-height: 300px;
    }

    @media (max-width: 1320px) {
        min-height: initial;
    }
}

.bLine-five {
    position: relative;
    padding-bottom: 15px;

    &::before {
        position: absolute;
        content: "";
        width: 50px;
        height: 3px;
        background: #F14647;
        bottom: 0;
        left: 0;
        border-radius: 50px;
    }
}

.txt_bor_b {
    // border-bottom: 2px solid #BCBCBC;
    padding-bottom: 5px;
}

.btnsize17 {
    max-width: 170px;
    min-width: 170px;
}


.arrow_back {
    display: flex;
    font-size: 18px;
    cursor: pointer;
    font-family: "Poppins-SemiBold";

    img {
        width: 22px;
        margin-right: 12px;
    }
}

// totle white box 

.totle_amount {
    .head6 {
        text-align: end;
        width: 120px;
    }
}

// filter section ==========================
.toggle_group-fil {
    border: 1px solid #E6E6E6;
    border-radius: 15px;
    width: fit-content;

    .toggle-btn-f {
        background: transparent;
        font-size: 14px;
        padding: 8px;
        width: 70px;
        text-align: center;

        &.active {
            background: #00A56A;
            color: #fff;
            border-radius: 15px;
        }
    }
}

.fillter_input_gr {
    label {
        color: #2E2E2E;
    }

    .light_pink {
        background: #FFF9F9;
    }
}

.extra-input-filled {
    .input_control {
        width: calc(100% + 68px);
        margin-left: -48px;
        position: relative;
        z-index: 9;
    }
}

.item_filter_box {
    width: 350px;
    min-width: 350px;
    background: $white;
    box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 15px 15px 240px 15px;
    position: relative;
    margin-right: 40px;
    height: 100%;

    .footerimg-filter {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 285px;
        width: 100%;
    }

    .accordion-item {
        border: 0;

    }

    .accordion-button:not(.collapsed) {
        background: $cusred;
        box-shadow: none !important;
        color: $white;
        border-radius: 5px;
    }

    .accordion-button:focus {
        border: 0 !important;
        box-shadow: none;
    }
}

// white box 
.white_box {
    background: #FFFFFF;
    box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 15px;
}

//restaurant group ============


.link_txt {
    color: $cusred;
    font-size: 14px;
    font-family: $popp-reg;
    background-color: transparent;

    &:hover {
        color: #2E2E2E;
    }
}

// middle line 
.bor_lineR {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        bottom: -2px;
        left: 0;
        width: 30px;
        height: 3px;
        background-color: #F14647;
        border-radius: 10px;
    }

    &::before {
        display: none;
    }
}

.middle-line-RL {
    position: relative;

    button,
    a {
        &::after {
            position: absolute;
            content: "";
            background: #c9c1c1;
            width: calc(50% - 110px);
            height: 2px;
            left: 0;
            opacity: 0.5;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 991px) {
                width: calc(50% - 80px);
            }
        }

        &::before {
            position: absolute;
            content: "";
            background: #c9c1c1;
            opacity: 0.5;
            width: calc(50% - 110px);
            height: 2px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 991px) {
                width: calc(50% - 80px);
            }
        }
    }
}

.checkout_section {
    padding: 50px 0;
}

// checkout section
.checkout_section {
    .col-left {
        flex: 1;
    }
}

// country name section
.country_name_section {
    flex: 1;

    .head4 {
        color: #F14647;
    }

    .head3 {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 45px;

        &::before {
            position: absolute;
            content: "";
            width: 80px;
            height: 3px;
            background: #F14647;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            border-radius: 50px;
        }
    }
}

//  ====================== Profile Section ============================
.profile {
    padding: 50px 0;
}

.Profile_info {
    display: flex;
    align-items: center;

    .profile_img {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        margin-right: 20px;
    }
}

.profile_tab {
    .tab-item-tr {
        color: #2E2E2E;
        font-size: 16px;
        font-family: "Poppins-SemiBold";
    }

    padding-bottom: 11px;
    border-bottom: 1px solid #BCBCBC;
}

.view_profile-bg-gray {
    background: #F3F3F3;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 15px;

    .cusbtn {
        min-width: 130px;
        text-align: center;
    }
}

//  ====================== Order Status ============================ 
.orderStatus {
    padding: 50px 0;
}

.order_status_track {}

.circle-tract {
    background-color: #F14647;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.trckbrd {
    position: relative;

    &::after {
        content: '';
        display: block;
        height: 3px;
        width: calc(100% - 100px);
        margin: auto;
        top: 20px;
        position: absolute;
        z-index: 0;
        right: 0;
        left: 0;
        border-color: #F14647;
        border-style: dashed;
        border-bottom: 0;
    }
}

.active_tr {
    position: relative;

    &::after {

        position: absolute;
        content: "";
        width: 30px;
        background: #FFFFFF;
        height: 30px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.order-tracking {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.dayShow-open {
    display: flex;
    align-items: center;

    .cirBox {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00A56A;
        font-size: 16px;
        color: #fff;
        border-radius: 50%;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
            background: transparent;
        }
    }
}

.time_modbox {
    font-size: 18px;
    font-family: "Poppins-Regular";

    .col-left {
        margin-right: 10px;
    }

    .col-right {
        color: #F14647;
    }
}

// ============================ media query 1199px ============================
@media (max-width: 1199px) {
    .item_filter_box {
        width: 300px;
        min-width: 300px;
        margin-right: 30px;
    }
}

// ============================ media query 991px ============================
@media (max-width: 991px) {
    .item_filter_box {
        width: 225px;
        min-width: 225px;
        margin-right: 20px;

        .accordion-button {
            padding: 10px;
        }

        .accordion-body {
            padding-right: 10px;
        }
    }

    .btnsize17 {
        max-width: 130px;
        min-width: 130px;
    }

    .extra-input-filled .input_control {
        margin-left: 0;
        width: 100%;
    }
}