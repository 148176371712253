// boostrap ===========
@import "../node_modules/bootstrap/scss/bootstrap.scss";

// varibale ===================
@import "../src/assets/scss/variable.scss";

// base ===================
@import "../src/assets/scss/base.scss";

// utility ====================
@import "../src/assets/scss/utility.scss";

// form ==================
@import "../src/assets/scss/form.scss";

// fonts ==================
@import "../src/assets/scss/fonts.scss";

// footer ======================
@import "../src/assets/scss/footer.scss";

// header ===================
@import "../src/assets/scss/header.scss";

// modal ==================
@import "../src/assets/scss/modal.scss";

// home ========================
@import "../src/assets/scss/main.scss";

//restaurant ================
@import "../src/assets/scss/restaurant.scss";

// custom btn
@import "../src/assets/scss/cusbtn.scss";

// custom Spinner ===============

@import "../src/assets/scss/spinner.scss"