.modal-dialog {
    max-width: 420px !important;
}

.modal_cus {
    .modal-body {
        padding: 50px 40px;

        .head4+p {
            font-size: 14px;
        }

        label {
            font-size: 14px;
        }
    }
}

.center-close-icon {
    .bodyModal {
        padding: 25px;
    }

    .modal-header {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -48px;
        background: #F14647;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 0;
    }

    .btn-close {
        background: url("../../../public/assets/img/modal_Delwhite.svg") no-repeat center;
        opacity: 1;
        opacity: 1;
        position: relative;
        left: -8px;
        box-shadow: none !important;
        outline: none !important;
    }
}

.img-logut {
    text-align: center;

    img {
        width: 100%;
        max-width: 90%;
        margin: auto;
        display: table;
    }
}

.center-close-icon {
    .bor_lineR::after {
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
    }
}