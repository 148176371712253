header {
    padding: 15px 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;

    .navbar-nav {
        li {
            margin-right: 40px;

            &:last-child {
                margin-right: 0;
            }

        }

        a {
            color: #333333;
            padding: 0;
            font-size: 18px;
            font-family: "Poppins-Midium";

            &:hover {
                color: #F14647;
            }

            &.active {
                position: relative;
                color: #F14647;

                &::after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: #F14647;
                    bottom: -4px;
                    left: 0;
                }
            }
        }
    }
}

.notification-view {
    position: relative;

    .notitxt {
        position: absolute;
        background: #F14647;
        width: 22px;
        height: 22px;
        border-radius: 50px;
        right: 0px;
        top: -6px;
        font-size: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Poppins-SemiBold";
    }
}

.header-btn {
    button {
        min-width: 110px;
    }

    a {
        font-size: 18px;
    }

    img {
        border-radius: 50%;
        transition: ease-in-out 0.9s;

        &:hover {
            outline: 1px solid #F14647;
            outline-offset: 0px;
        }
    }
}

.btnCont-mt {
    margin-top: 90px;
}

@media (max-width: 991px) {
    header {
        #navbarScroll {
            background: #fff;
            padding: 30px 15px;
            z-index: 99;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
        }

        .navbar-nav li {
            margin-bottom: 15px;
        }
    }
}