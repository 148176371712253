body {
    margin: 0;
    background-color: #fff;
}

html {
    scroll-behavior: smooth;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

button {
    border: none;
    line-height: normal;
}

button:focus,
input:focus {
    outline: none !important;
}

.content {
    width: 100%;
    max-width: 1920px;
    margin: auto;
}

.cus_container {
    max-width: 1624px;
    padding: 0 120px;
    margin: auto;

    @media (max-width: 1440px) {
        padding: 0 86px;
    }

    @media (max-width: 1320px) {
        padding: 0 57px;
    }

    @media (max-width: 1199px) {
        padding: 0 25px;
    }
}

.light_b {
    color: #2E2E2E !important;
}

.line-content-middle {
    background: #c9c1c1;
    width: 100%;
    opacity: 0.5;
    height: 2px;
}


.txt {
    font-size: 16px;
    color: #666666;
    font-family: $popp-reg;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2E2E2E;
}

.head1 {
    font-family: $popp-bo;
    font-size: 62px;
    line-height: 1.3;

    span {
        color: #F14647;
    }
}

textarea,
input {
    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.head3 {
    font-size: 34px;
    color: #2E2E2E;
    line-height: 1.5;
    margin-bottom: 0;
    font-family: $popp-bo;

    span {
        color: $cusred;
    }
}

.head5 {
    font-family: $popp-semibo;
    font-size: 20px;
}

.head6 {
    font-family: $popp-semibo;
    font-size: 16px;
}

.head4 {
    // font-family: $popp-semibo;
    font-family: "Poppins-Bold";
    font-size: 30px;
}

// media query 1199px 
@media (max-width: 1320px) {
    .head1 {
        font-size: 55px;
    }
}

// media query 1199px 
@media (max-width: 1199px) {
    .head1 {
        font-size: 50px;
    }

    .head3 {
        font-size: 30px;
    }
}

// media query 991px 
@media (max-width: 991px) {
    .head1 {
        font-size: 36px;
        line-height: 1.2;
    }
}