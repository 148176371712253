.main-load {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
}

.spinner {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    box-sizing: border-box;
    border: 2px groove rgb(189, 65, 65);
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -16px 0px 0px -16px;
    border-top-color: rgba(255, 255, 255, 1);
    border-left-color: rgba(255, 255, 255, 0.9);
    border-bottom-color: rgba(255, 255, 255, 0.8);
    border-right-color: rgba(255, 255, 255, 0.7);
    animation: rotate 1000ms infinite linear;
    transform: translateZ(0px);
}

.dotted {
    width: 64px;
    height: 64px;
    margin: 32px 0px 0px -32px;
    border-width: 8px;
    border-style: dotted;
    animation: rotate 1000ms infinite steps(12, end), linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.right_box_hug {
    position: relative;

}


.loader-cus {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;

    .sl-spinner2 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        background: #00000036;
        height: 100%;
    }
}