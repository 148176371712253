.label-span a {
    color: $cusred;
}

.hoverbtn {
    font-family: $popp-semibo;
    font-size: 16px;
    padding: 10px 15px;
    color: $cusred;
    background: transparent;
    border: 1px solid $cusred;
    border-radius: 10px;
    transition: ease-in-out 0.5s;

    &:hover {
        background: $cusred;
        color: $white;
    }
}

.num-g-input {

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.iconDre_size {
    width: 20px;
    height: 20px;
}

.cusbtn {
    font-family: $popp-semibo;
    font-size: 16px;
    padding: 10px 15px;
    color: $white;
    background: $cusred;
    border: 1px solid $cusred;
    transition: ease-in-out 0.5s;
    cursor: pointer;
    border-radius: 10px;

    &:hover {
        background: transparent;
        color: $cusred;
    }
}

.cardbtn {
    font-family: $popp-semibo;
    font-size: 16px;
    padding: 10px 15px;
    color: #2E2E2E;
    background-color: #fff;
    border: 1px solid #BCBCBC;
    border-radius: 5px;
    transition: ease-in-out 0.5s;
    cursor: pointer;

    &.active_card {
        border: 1px solid #F14647;
    }
}

.check-container {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 16px;
    height: 16px;
    padding-left: 30px;
    padding-top: 1px;
    position: relative;
    top: 6px;

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: $white;
        border: 1px solid $cusred;
        border-radius: 2px;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked~.checkmark {
        background-color: $cusred;
        border: 1px solid $cusred;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}

.passwor-icon-g {
    position: relative;

    input {
        padding-right: 28px;
    }

    .icon-password {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
    }
}

.selectbox-in {
    position: relative;

    select {
        appearance: none;
        padding-right: 5px;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #2E2E2E;
        width: 2px;
        height: 80%;
    }
}

.multiple-input-otp {
    display: flex;

    .input_control {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.otb_conatiner {
    .input_control {
        width: 46px !important;
        margin: auto;
    }

}

.input_control.input-inside-img {
    background-image: url("../../../public/assets/img/searchicon.svg");
    background-position: center right 15px;
    background-repeat: no-repeat;
    background-size: 23px;
    padding-right: 50px;
}

label {
    font-size: 16px;
    color: #999999;
    font-family: $popp-reg;
}

.input_control {
    font-size: 16px;
    color: #2E2E2E;
    background: #F3F3F3;
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    padding: 9px 15px;
    width: 100%;
    height: 46px;
}

.otp_box {
    div {
        justify-content: space-between;
    }

    .otp_control {
        font-size: 16px;
        color: #2E2E2E;
        background: #F3F3F3;
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        padding: 9px 15px;
        width: 46px !important;
        height: 46px;
    }
}

.textarea {
    font-size: 16px;
    color: #2E2E2E;
    background: #F3F3F3;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    padding: 9px 15px;
    width: 100%;
    height: 150px;
    resize: none;
}

.num-g-input {
    display: flex;
    align-items: center;
    background: #F3F3F3;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    padding-left: 10px;

    .input_control {
        border: 0;
    }

    span {
        font-size: 16px;
        font-family: $popp-reg;
    }

    select {

        &:hover,
        &:focus,
        &:focus-visible {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }
}

.btn_res {
    padding: 4px 7px;
    font-size: 14px;
    color: $white;
    border-radius: 5px;
    margin-left: 6px;

    h6 {
        font-size: 16px;
    }

    &.open {
        background: #00A56A;
    }

    &.close {
        background: #F14647;
    }
}

@media (max-width: 991px) {
    .input_control {
        height: 40px;
    }

    .hoverbtn {
        font-size: 14px;
        padding: 8px 15px;
    }

    .cusbtn {
        font-size: 14px;
        padding: 8px 15px;
    }

    .btn_res {
        font-size: 12px;
    }
}

.payment_card_view {
    border: 1px solid #F4F4F4;
    width: fit-content;

    .radio-group {
        position: relative;
        top: -5px;
    }
}

.ElementsApp,
.ElementsApp .InputElement {
    padding: 10px !important;
}

.fillinput {
    font-size: 16px;
    color: #2E2E2E;
    background: #F3F3F3;
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    padding: 9px 15px;
    width: 100%;
    height: 46px;
}