.checkbox-btn {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    margin-right: 25px;
    margin-top: 2px;

    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #FFFFFF;
        border-radius: 50%;
        border: 1px solid #F14647;
    }

    /* When the radio button is checked, add a blue background */
    input:checked~.checkmark {
        background-color: #fff;
        border: 1px solid #F14647;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked~.checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkmark:after {
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #F14647;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.newbtn_addcard {
    color: #F14647;
    font-size: 18px;
    background: transparent;
}

.ratting_sec {
    .star-svg {
        width: 22px !important;
        height: 22px !important;
    }
}