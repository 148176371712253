// fonts varibale ==============
$popp-reg: "Poppins-Regular";
$popp-semibo: "Poppins-SemiBold";
$popp-bo: "Poppins-Bold";


// Color Veriable ================
$white: #fff;
$cusred: #F14647;
$black: #2E2E2E;

.blackC {
    color: #2E2E2E !important;
}